/**
 * Copyright (C) 2021 THL A29 Limited, a Tencent company.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import SyntaxBase from '@/core/SyntaxBase';

// 拼音语法
export default class Ruby extends SyntaxBase {
  static HOOK_NAME = 'ruby';

  makeHtml(str) {
    if (!this.test(str)) {
      return str;
    }
    return str.replace(this.RULE.reg, `$1<ruby>$2<rt>$3</rt></ruby>$4`);
  }

  rule() {
    const ret = { begin: '(^| )\\{', end: '\\}( |$)', content: '([^\n]+?)\\|([^\n]+?)' };
    ret.reg = new RegExp(ret.begin + ret.content + ret.end, 'g');
    return ret;
  }
}
