/**
 * Copyright (C) 2021 THL A29 Limited, a Tencent company.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
export default {
  bold: 'Bold',
  code: 'Code',
  graph: 'Graph',
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
  h4: 'Heading 4',
  h5: 'Heading 5',
  header: 'Header',
  insert: 'Insert',
  italic: 'Italic',
  list: 'List',
  quickTable: 'Quick Table',
  quote: 'Quote',
  size: 'Size',
  color: 'Text Color & Background',
  strikethrough: 'Strikethrough',
  sub: 'Sub',
  sup: 'Sup',
  togglePreview: 'Toggle Preview',
  fullScreen: 'Full Screen',
  image: 'Image',
  audio: 'Audio',
  video: 'Video',
  link: 'Link',
  hr: 'Horizontal Rule',
  br: 'New Line',
  toc: 'Table Of Content',
  pdf: 'PDF',
  word: 'Word',
  table: 'Table',
  'line-table': 'Line Table',
  'bar-table': 'Bar Table',
  formula: 'Formula',
  insertFormula: 'Insert Formula',
  insertFlow: 'Insert Flow',
  insertSeq: 'Insert Seq',
  insertState: 'Insert State',
  insertClass: 'Insert Class',
  insertPie: 'Insert Pie',
  insertGantt: 'Insert Gantt',
  checklist: 'Checklist',
  ol: 'Ordered List',
  ul: 'Unordered List',
  undo: 'Undo',
  redo: 'Redo',
  previewClose: 'Preview Close',
  codeTheme: 'Code Theme',
  switchModel: 'Switch Model',
  switchPreview: 'Switch Preview',
  switchEdit: 'Switch Edit',
  classicBr: 'Classic New Line',
  normalBr: 'Normal New Line',
  settings: 'Settings',
  mobilePreview: 'Mobile Preview',
  copy: 'Copy',
  export: 'Export',
  underline: 'Underline',
  pinyin: 'Pinyin',
  pastePlain: 'Paste as Plain Text',
  pasteMarkdown: 'Paste as Markdown',
  hide: 'Hide (ctrl+0)',
  exportToPdf: 'Export to PDF',
  exportScreenshot: 'Screenshot',
  exportMarkdownFile: 'Export Markdown File',
  exportHTMLFile: 'Export preview HTML File',
  'H1 Heading': 'H1 Heading',
  'H2 Heading': 'H1 Heading',
  'H3 Heading': 'H1 Heading',
  complement: 'Complement',
  summary: 'Summary',
  justify: 'justify',
  justifyLeft: 'Left',
  justifyCenter: 'Center',
  justifyRight: 'Right',
};
